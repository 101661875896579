// CSS
import '../scss/main.scss'

// Libs
import $ from 'jquery';
import './utilities/imagesFader';

window.responsivelyLazy = {};
import '../../node_modules/responsively-lazy/responsivelyLazy';

// Utilities
import factory from './utilities/factory';
import masonry from './utilities/masonry';

// Modules
import Header from './modules/Header';
import ScrollManager from './modules/ScrollManager';

$(() => {
  masonry();
  factory(ScrollManager, $('.js-scroll'));
  factory(Header, $('.js-header'));
});


import $ from 'jquery';

export default (Cls, $els) => {
  const instances = [];
  if ($els.length > 0) {
    $els.each((idx, el) => {
      instances.push(new Cls($(el)));
    });
  }

  return instances;
};

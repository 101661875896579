class Header {
  constructor($el) {
    this.$el = $el;
    this.$toggler = $el.find('.js-header_toggler');

    this.cssState = {
      open: 'is-open',
    };

    this.$toggler.on('click', this.onToggleNav.bind(this));
  }

  onToggleNav() {
    this.$el.toggleClass(this.cssState.open);
  }
}

export default Header;

import Masonry from 'masonry-layout';

export default () => {
  const el = document.querySelector('.js-grid');

  if (!el) {return;}

  const grid = new Masonry(el, {
    columnWidth: '.js-grid_itemSizer',
    itemSelector: '.js-grid_item',
    percentPosition: true,
  });
};

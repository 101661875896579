// ============================================
// Scroll Manager
// ============================================


// Libraries
// ============================================

import $ from 'jquery';
import 'browsernizr/test/touchevents';
import Modernizr from 'browsernizr';

// Class
// ============================================

class ScrollManager {
  constructor($el) {
    // Break off if on a touch device
    if (Modernizr.touchevents) {return;}

    // Collect Dom
    this.$el = $el;
    this.$dom = {
      el: this.$el,
      controls: this.$el.find('.js-scroll_control'),
      inner: this.$el.find('.js-scroll_inner'),
      items: this.$el.find('.js-scroll_item'),
      htmlBody: $('html, body'),
      win: $(window),
    };

    this.$dom.controlUp = this.$dom.controls.filter(function cb() {
      return $(this).data('dir') === 'up';
    });

    this.$dom.controlDown = this.$dom.controls.filter(function cb() {
      return $(this).data('dir') === 'down';
    });

    // Global scroll Settings
    this.lastKnownScroll = 0;
    this.isTicking = false;
    this.isAnimating = false;
    this.controlsHideThreshold = 30;
    this.scrollAnimationSpeed = 200;

    this.metrics = {};
    this.metrics.sectionsLength = this.$dom.items.length;
    this.currentSection = 0;

    // Css Classes
    this.cssState = {
      disabled: 'is-disabled',
      visible: 'is-visible',
    };

    // Init
    this.updateState();
    this.$dom.win.on('scroll.scrollManager', $.proxy(this.handlerScroll, this));
    this.$dom.win.on('resize.scrollManager', $.proxy(this.updateState, this));
    this.$dom.controls.on('click.scrollManager', $.proxy(this.handlerClick, this));
  }

  // Calculate all dimensions
  // ============================================

  updateState() {
    this.computeDimensions();
    this.updateScroll();

    if (this.metrics.innerHeight > this.metrics.viewportHeight) {
      this.$dom.controls.addClass(this.cssState.visible);
    } else {
      this.$dom.controls.removeClass(this.cssState.visible);
    }
  }

  computeDimensions() {
    this.metrics.viewportHeight = this.$dom.win.height();
    this.metrics.scrollThreshold = this.metrics.viewportHeight / 2;
    this.metrics.innerHeight = this.$dom.inner.outerHeight(false);

    const sections = [];

    this.$dom.items.each((idx, el) => {
      const $el = $(el);
      const offsetTop = $el.offset().top;
      const height = $el.outerHeight(false);

      let position = (offsetTop - this.metrics.scrollThreshold) + (height / 2);
      if (position < 0) {position = 0;}

      sections.push({
        position,
        offsetTop,
        height,
      });
    });

    this.metrics.sections = sections;
  }

  // Arrow click event handler
  // ============================================

  handlerClick(e) {
    e.preventDefault();

    const $el = $(e.currentTarget);
    let scrollAmount = 0;
    let nextSection = 0;

    if ($el.attr('disabled') || this.isAnimating) { return; }

    this.isAnimating = true;

    if ($el.data('dir') === 'up') {
      if (this.currentSection === 0) {
        nextSection = 0;
        scrollAmount = 0;
      } else {
        nextSection = this.currentSection - 1;
        scrollAmount = this.metrics.sections[nextSection].position;
      }

    } else {
      const lastSlideIdx = this.metrics.sectionsLength - 1;
      if (this.currentSection === lastSlideIdx) {
        nextSection = lastSlideIdx;
        scrollAmount = this.metrics.sections[lastSlideIdx].position + this.metrics.viewportHeight;
      } else {
        nextSection = this.currentSection + 1;
        scrollAmount = this.metrics.sections[nextSection].position;
      }

    }

    this.$dom.htmlBody.animate({
      scrollTop: scrollAmount,
    }, this.scrollAnimationSpeed, () => {
      this.isAnimating = false;
      this.currentSection = nextSection;
    });
  }


  // Slider scroll event handler
  // ============================================

  handlerScroll() {
    this.lastKnownScroll = this.$dom.win.scrollTop();
    this.requestTick();
  }


  // Request on rAF
  // ============================================

  requestTick() {
    if (!this.isTicking) {
      this.isTicking = true;
      window.requestAnimationFrame(this.updateScroll.bind(this));
    }
  }


  // Scroll callback
  // ============================================

  updateScroll() {
    this.isTicking = false;
    const currentScroll = this.lastKnownScroll;

    if (currentScroll + this.metrics.viewportHeight >= this.metrics.innerHeight - this.controlsHideThreshold) {
      this.$dom.controlDown.addClass(this.cssState.disabled);
    } else {
      this.$dom.controlDown.removeClass(this.cssState.disabled);
    }

    if (currentScroll <= this.controlsHideThreshold) {
      this.$dom.controlUp.addClass(this.cssState.disabled);
    } else {
      this.$dom.controlUp.removeClass(this.cssState.disabled);
    }

    // Determine the current section
    this.metrics.sections.forEach((val, idx) => {
      if (val.position - (val.height / 2) <= currentScroll) {
        this.currentSection = idx;
      }
    });

    // console.log(this.currentSection);
  }
}


export default ScrollManager;
